@import "../../styles/variables.scss", "../../styles/functions.scss";

.colors {
	padding: lh(3);
	> ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		gap: lh(1);
		> li {
			margin: 0;
			padding: 0;
		}
	}

	div.color_list {
		h2 {
			button {
				background: none;
				border: none;
			}
			svg {
				position: relative;
				top: 0.4rem;
				height: 1em;
				line-height: lh(1);
				fill: white;
			}
		}

		> ul {
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			gap: lh(1);
			> li {
				margin: 0;
				padding: 0;
			}
		}
	}

	input {
		margin: lh(1);
		width: lh(30);
		resize: none;
		font-family: $font_monospace;
	}

	.color_swatch {
		position: relative;
		width: lh(6);
		// padding: lh(1);
		font-family: $font_monospace;
		box-shadow: 0 0 0 lh(0.2) white;
		justify-content: center;
		border-radius: lh(0.25);

		.color {
			// padding-top: lh(5);
			position: relative;
			min-height: lh(6);

			svg.remove,
			svg.star,
			svg.palette,
			svg.bars {
				position: absolute;
				height: lh(0.8);
				cursor: pointer;
				opacity: 0.25;

				&:hover {
					opacity: 0.6;
				}
			}

			svg.remove {
				top: lh(0.25);
				right: lh(0.25);
			}

			svg.star {
				display: none;
				top: lh(0.25);
				right: lh(0.25);
			}

			svg.palette {
				top: lh(1.25);
				right: lh(0.25);
			}

			svg.bars {
				top: lh(2.25);
				right: lh(0.25);
			}

			input {
				position: absolute;
				background-color: transparent;
				border: none;
				outline: none;
				text-align: center;
				bottom: 0;
				margin: 0;
				padding: lh(0.25);
				width: 100%;
				resize: none;
				font-family: $font_monospace;
			}
		}
		div.rows {
			> ul.color_row {
				list-style: none;
				margin: 0;
				padding: 0;
				display: flex;

				li {
					height: 1rem;
					display: block;
					margin: 0;
					padding: 0;
					flex: 1 0 0;
				}
			}
		}
		.description {
			padding: lh(0.25);
			//padding-top: lh(2);
			font-size: 0.7rem;
			background: white;
			color: black;
			opacity: 0.75;
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-template-rows: repeat(3, 1fr);
			grid-column-gap: 0px;
			grid-row-gap: 0px;

			div {
				align-self: center;
				justify-self: center;

				svg {
					display: block;
					height: lh(0.7);
					cursor: pointer;
					align-self: center;
					opacity: 0.8;
				}

				button {
					display: block;
					padding: 0;
				}

				input {
					display: inline-block;
					width: 100%;
					text-align: center;
					padding: 0;
					margin: 0;
					border: 0;
				}
			}

			div:nth-child(3),
			div:nth-child(8),
			div:nth-child(13) {
				justify-self: end;
			}

			div:nth-child(2) {
				grid-column: 2 / span 3;
				text-align: center;
			}
		}
	}
}
