@import "../../styles/variables.scss", "../../styles/functions.scss";

div.SignInNew {
	.vapor_window {
		// max-width: lh(20);
		// width: lh(18);
	}

	.vapor_window_content {
		p {
			text-align: center;
		}
	}

	.vapor_button {
		// button {
		// 	display: block;
		// 	// margin: lh(1) auto;
		// 	width: 100%;
		// }
	}
}
