@use "sass:math";

// :root {
// 	--color-background: 240 10% 5%;
// 	--color-background-light: 240 5% 10%;

// 	--color-background-100: 240 10% 5%;
// 	--color-background-200: 240 5% 10%;
// 	--color-background-300: 240 5% 15%;

// 	/* colors */
// 	--clr-dark: 230 35% 7%;
// 	--clr-light: 231 77% 90%;
// 	--clr-white: 0 0% 100%;

// 	/* font-sizes */
// 	--fs-900: clamp(5rem, 8vw + 1rem, 9.375rem);
// 	--fs-800: 3.5rem;
// 	--fs-700: 1.5rem;
// 	--fs-600: 1rem;
// 	--fs-500: 1rem;
// 	--fs-400: 0.9375rem;
// 	--fs-300: 1rem;
// 	--fs-200: 0.875rem;

// 	/* font-families */
// 	--ff-serif: "Bellefair", serif;
// 	--ff-sans-cond: "Barlow Condensed", sans-serif;
// 	--ff-sans-normal: "Barlow", sans-serif;
// 	--ff-mono: "EllographCF", monospace;

// 	--width-max: 50rem;
// 	--width-min: 40rem;
// }

// --------------------------
// Convenience functions
// --------------------------
@function lh($multiplier) {
	@return $font_base_line-height * $multiplier + rem;
}

// --------------------------
// Typography
// --------------------------
//$font_base-size: 18;
$font_base-line-height: 1.5;
// $font_headlines: "VisbyRoundCF", sans-serif;
// $font_body: "VisbyRoundCF", sans-serif;
// $font_headlines: "GreycliffCF", sans-serif;
$font_headline: "VisbyRoundCF", sans-serif;
$font_body: "GreycliffCF", sans-serif;
$font_monospace: "EllographCF", monospace;

$font-size-base: 18;
// Golden Ratio Sizes
$font-size-1: 1;
$font-size-2: 1.618;
$font-size-3: 2.618;
$font-size-4: 4.236;
$font-size-5: 6.854;
$font-size-6: 11.089;
$font-size-m1: 0.618;
$font-size-m2: 0.382;
$font-size-m3: 0.236;

$font-size-h1: 3.998;
$font-size-h2: 2.827;
$font-size-h3: 1.999;
$font-size-h4: 1.414;

// Major third scaling
$font-size-100: 0.512;
$font-size-200: 0.64;
$font-size-300: 0.8;
$font-size-400: 1;
$font-size-500: 1.25;
$font-size-600: 1.563;
$font-size-700: 1.953;
$font-size-800: 2.441;
$font-size-900: 3;

// --------------------------
// Sizing
// --------------------------
$width_default: 50rem;
$width_default: 70rem;
$width_mediaquery: 40rem;

// --------------------------
// Misc
// --------------------------
$border_radius: #{(calc($font_base_line-height / 4)) + "rem"};

// --------------------------
// Colors: http://colorizer.org/
// --------------------------
$color_background-000: (280, 15, 5);
$color_background-100: (280, 15, 10);
$color_background-200: (280, 15, 20);
$color_background-300: (280, 15, 30);
$color_background-400: (280, 15, 40);

$color_background-000: (0, 0, 0);
$color_background-100: (0, 0, 3);
$color_background-200: (0, 0, 6);
$color_background-300: (0, 0, 12);
$color_background-400: (0, 0, 24);

$color_white-000: (0, 0, 80);
$color_white-100: (0, 0, 83);
$color_white-200: (0, 0, 85);
$color_white-300: (0, 0, 87);
$color_white-400: (0, 0, 90); // Mid point "normal"
$color_white-500: (0, 0, 93);
$color_white-600: (0, 0, 95);
$color_white-700: (0, 0, 98);
$color_white-800: (0, 0, 100);

$color_bright_orange: (30, 100, 100);
$color_bright_yellow: (60, 100, 100);
$color_bright_green: (120, 100, 100);
$color_bright_teal: (180, 100, 100);
$color_bright_blue: (240, 100, 100);
$color_bright_purple: (270, 100, 100);
$color_bright_pink: (300, 100, 100);
$color_bright_red: (360, 100, 100);
$color_bright_orange: (30, 100, 100);

$color_font_white: (0, 0, 100);
