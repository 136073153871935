@use "../Styles/Vapor.scss";
@import "../../styles/functions.scss";

div.vapor_layout_center {
	display: flex;
	height: 100vh;
	align-items: center;
	justify-content: center;
	// background: Vapor.$color-background;
}

div.vapor_layout_full_screen {
	// background: Vapor.$color-background;
}

@media (max-width: lh(24)) {
	div.vapor_layout_center {
		display: block;
		// height: 100vh;
		// align-items: center;
		// justify-content: center;
		// background: $color-background;
	}
	div.vapor_window {
		.window {
			margin: 0 !important;
			width: 100% !important;
		}

		width: 100% !important;
		margin: 0 !important;
		// background: blue;

		div.footer {
			margin: 0 !important;
			// padding: lh(0.5);
		}
	}
}
