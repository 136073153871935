@use "./Design/Styles/Vapor.scss";
@import url("./styles/reset.css");
@import "./styles/typography.scss";
@import "./styles/variables.scss", "./styles/functions.scss";
@import "./styles/css_variables.scss";

html,
body {
	min-height: 100vh;
}

body {
	color: hsb($color_font_white);
	// background-color: hsb(Vapor.$color_background-000);
	background-color: Vapor.$color-background;
	min-height: 100vh;
	display: grid;
	grid-template-rows: min-content 1fr;
	overflow-x: hidden;
	padding: 0;
	margin: 0;
	//z-index: 0;
}

// ::-webkit-scrollbar {
// 	background-color: transparent;
// 	width: lh(0.5);
// }

// ::-webkit-scrollbar-track {
// 	//background: blue;
// }
// ::-webkit-scrollbar-thumb:window-inactive,
// ::-webkit-scrollbar-thumb {
// 	background-color: rgba(255, 255, 255, 0.125);
// 	border-radius: lh(0.125);
// }

// ::-webkit-scrollbar-thumb:window-inactive {
// 	background-color: rgba(255, 255, 255, 0.1);
// }

// ::-webkit-scrollbar-corner {
// 	background-color: transparent;
// }

body,
* {
	box-sizing: border-box;
}

/* make images easier to work with */
img,
picture {
	max-width: 100%;
	display: block;
}

/*
https://scrimba.com/learn/spacetravel/introduction-co9754ea5b87864fba221a504
http://www.workwithcolor.com/color-converter-01.htm
*/

.grid::after {
	content: " ";
	display: block;
	position: absolute;
	height: 100%;
	width: 100%;
	pointer-events: none;
	background-image: linear-gradient(
		0deg,
		transparent calc(lh(0.5) - 1px),
		hsb((330, 80, 35)) calc(lh(0.5) - 1px),
		hsb((330, 80, 35)) calc(lh(0.5))
	);
	background-size: lh(0.5) lh(0.5);
}

button.LinkButton {
	background: transparent;
	border: none;
	margin: 0;
	padding: 0;
	color: var(--color_white-400);
}
