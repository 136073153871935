div.doom {
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		display: block;
		fill: white;
		width: 35rem;
		filter: drop-shadow(-3rem 0 0px rgb(255 0 0 / 0.75)) drop-shadow(0 3rem 0px rgb(0 255 0 / 0.75))
			drop-shadow(3rem 0 0px rgb(0 0 255 / 0.75));
		background-blend-mode: multiply;
	}
}
