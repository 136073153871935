@import "../../styles/variables.scss", "../../styles/functions.scss";
@import "../Styles/Variables.scss";

div.vapor_layout_default {
	background-color: $color_background_400;
	header {
		position: fixed;
		background-color: $color_background_400;
		// background-color: transparent;
		// background-color: white;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 100;
		border-bottom: $border-width solid $color-text;
		// filter: invert(100%);
		// mix-blend-mode: difference;
		// opacity: 0.5;
	}

	.MainWrapper {
		display: flex;
		min-height: 100vh;
		flex-direction: column;
		justify-content: space-between;
	}

	main {
		display: block;
		margin-top: 0rem;
		padding-top: 3rem;
	}

	footer {
		div.SignOff {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-top: lh(1);
			padding: lh(1);
			font-size: var(--font-size-300);
			font-family: $font_monospace;
			opacity: 0.25;
			max-width: $width_default;
			margin: 0 auto;
			gap: lh(1);
			mix-blend-mode: difference;

			svg {
				margin-top: 0.25rem;
				fill: var(--color_background-400);
				fill: $color_background-700;
				fill: white;
				height: var(--font-size-300);
				width: var(--font-size-300);
			}

			> span {
				flex: 1 1 content;
				//max-width: calc($width_default / 2);
				color: var(--color_background-400);
				color: $color_background-700;
				color: white;

				a {
					color: var(--color_background-400);
					color: $color_background-700;
					color: white;
					text-decoration: none;
				}
			}

			> span:nth-child(2) {
				text-align: right;
			}
		}
	}
}

@media (max-width: calc($width-mediaquery + 10rem)) {
	footer {
		.SignOff {
			display: block;
			> span {
				text-align: center !important;
			}
		}
	}
}
