@use "sass:math";
@use "./Styles/Variables.scss";
@use "../styles/variables.scss";
@use "../styles/functions.scss";
@import "./Styles/Variables.scss";
@import "./Styles/Utility.scss";

div.vapor_window {
	div.window {
		color: $color-text;
		width: lh(20);
		font-family: var(--font-family-monospace);
		// font-family: var(--font-family-body);
		//margin: lh(1);
		position: relative;
		// border: $border-width solid $color-text;

		//border: $border-width solid $color-text;
		box-shadow: 0 0 0 calc($border-width * 1) $color-borders, 0 0 0 calc($border-width * 2) $color-background;
		border-radius: lh(0.25);
		// box-shadow: 0 0 0 calc($border-width / 2) $color-borders, 0 0 0 calc($border-width / 2) $color-borders inset;
		// background: $color-background;
		// box-shadow: 0 0 lh(0.5) lh(0.5) $color-background;
		// border-bottom: none;
		margin: lh(1);
		background: $color-background;
		background: $color-text;

		& ::selection {
			background: $color-text;
			color: $color-background;
		}

		p {
			margin: 0 0 lh(1);
		}

		div.vapor_window_title {
			box-shadow: 0 0 0 calc($border-width / 2) $color-borders, 0 0 0 calc($border-width / 2) $color-borders inset;
			padding: lh(0.5);
			// background: $color-background;
			// border-bottom: $border-width solid $color-text;
			background: $color-background_400;
			border-radius: lh(0.25);

			h1 {
				@include vr_font($font-size-700);
				display: block;
				font-family: $font_monospace;
				font-weight: 600;
				text-transform: uppercase;
				text-align: center;
				margin: 0;
			}

			svg {
				fill: $color-text;
				height: var(--font-size-900);
				width: var(--font-size-900);
				vertical-align: text-bottom;
				margin-right: lh(0.5);
			}
		}

		div.vapor_window_content {
			// box-shadow: 0 0 0 calc($border-width / 2) $color-borders, 0 0 0 calc($border-width / 2) $color-borders inset;
			box-shadow: 0 0 0 calc($border-width / 2) $color-borders, 0 0 0 calc($border-width / 2) $color-borders inset;
			padding: lh(1);
			transition: all 0.5s linear;
			background: $color-background_400;
			border-radius: lh(0.25);

			& > :last-child {
				margin-bottom: 0;
			}
		}

		div.vapor_window_divider {
			min-height: lh(1);
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
			text-align: center;
			text-transform: uppercase;
			font-family: $font_monospace;
			font-weight: 800;
			opacity: 1;
			margin: lh(1) 0;

			&:before,
			&:after {
				content: "";
				border-top: $border-width solid $color-text;
				flex: 1 0 lh(1);
			}

			&.children {
				&:before,
				&:after {
					margin: 0 lh(1) 0 0;
				}

				&:after {
					margin: 0 0 0 lh(1);
				}
			}
		}

		blockquote {
			font-style: italic;
			margin: lh(1);
			span.author {
				font-style: normal;
				display: block;
				text-align: right;
				&:before {
					content: "- ";
				}
			}
		}
	}
	div.footer {
		display: flex;
		margin-top: lh(-0.5);
		padding: 0 lh(1.5);
		font-family: var(--font-family-monospace);
		font-size: var(--font-size-300);
		text-shadow: 0 0 lh(0.25) $color-background;

		svg {
			margin-top: -2px;
			display: inline-block;
			height: var(--font-size-300);
			fill: white;
			background: none;
			vertical-align: middle;
		}

		> div {
			opacity: 0.5;
		}

		> div:nth-of-type(1) {
		}
		> div:nth-of-type(2) {
			//box-shadow: 0 0 5px 5px white inset;
			flex-grow: 1;

			div {
				background: $color-text;
				margin: lh(0.25) lh(1);
				height: lh(0.5);
			}
		}
		> div:nth-of-type(3) {
			text-transform: uppercase;
		}

		a {
			color: white;
			text-decoration: none;
		}
	}
}
