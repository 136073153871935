@use "../../Design/Styles/Vapor.scss";
@import "../../styles/variables.scss", "../../styles/functions.scss";

div.SignIn {
	height: 100vh;
	padding-top: lh(3);

	div.SignInForm {
		display: block;
		position: relative;
		width: 30rem;
		margin: 0 auto;
		padding: lh(1) lh(2) lh(1) lh(2);
		background-color: hsb($color_background-100);
		border-radius: lh(1);
		//    box-shadow: 0 0 lh(.5) lh(.25) hsb([280, 0, 0]), -1px -1px 0px 1px hsb([280, 15, 15]) inset, 1px 1px 0px 1px hsb([280, 15, 18]) inset;

		h1 {
			margin: lh(1) 0;
			margin-bottom: lh(1);
			font-size: lh(1.5);
			line-height: lh(1.5);
			text-transform: uppercase;
			text-align: left;
			font-weight: 600;
		}

		p {
			text-align: left;
			font-weight: 500;

			span {
				font-weight: 600;
				text-decoration: underline;
				cursor: pointer;
			}
		}

		button.ButtonFancy {
			display: block;
			width: 100%;
			margin: 1.5rem 0;
		}

		div.or {
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
			text-align: center;
			text-transform: uppercase;
			font-family: $font_monospace;
			font-weight: 800;
			opacity: 0.25;

			&:before,
			&:after {
				content: "";
				border-top: 2px solid;
				margin: 0 lh(1) 0 0;
				flex: 1 0 lh(1);
			}

			&:after {
				margin: 0 0 0 lh(1);
			}
		}

		.alternative {
			display: block;
			margin: 1.5rem 0;
			width: 100%;
			text-align: center;
			opacity: 0.5;
			font-style: italic;
		}

		.errorTitle {
			font-family: var(--ff-mono);
			font-size: 1.5rem;
			line-height: 1.5rem;
			text-transform: uppercase;
			font-weight: 600;
			margin: 0;
			color: hsb($color_bright_red);
		}

		.errorMessage {
			font-family: var(--ff-mono);
			font-size: 1rem;
			line-height: 1.5rem;
			display: block;
			font-weight: 400;
			margin-bottom: 1.5rem;
			color: hsb($color_bright_red);

			strong {
				font-weight: 800;
			}
		}
	}
}

// sass-lint:disable-next-line unknownAtRules
@property --rotate {
	syntax: "<angle>";
	initial-value: 132deg;
	inherits: false;
}

:root {
	--rotate: 132deg;
}

.BoxGlow {
	// Cyber
	$color_1: hsb($color_bright_teal);
	$color_2: hsb($color_bright_purple);
	$color_3: hsb($color_bright_pink);

	&:before,
	&:after {
		content: "";
		position: absolute;
		background-image: linear-gradient(var(--rotate), $color_1, $color_2 40%, $color_3);
		animation: SignInSpin 8s linear infinite;
	}

	&:before {
		top: -2px;
		left: -2px;
		width: calc(100% + 4px);
		height: calc(100% + 4px);
		border-radius: lh(1);
		z-index: -1;
	}

	&:after {
		top: 0;
		left: 0;
		right: 0;
		z-index: -1;
		height: 100%;
		width: 100%;
		margin: 0 auto;
		transform: scale(0.8);
		filter: blur(lh(4));
		opacity: 0.75;
		transition: opacity 0.5s;
	}

	&.Cyber {
		$color_1: hsb($color_bright_blue);
		$color_2: hsb($color_bright_purple);
		$color_3: hsb($color_bright_pink);
		&:before,
		&:after {
			background-image: linear-gradient(var(--rotate), $color_1, $color_2 40%, $color_3);
		}
	}

	&.Red {
		$color_1: hsb((320, 100, 100));
		$color_2: hsb((355, 100, 100));
		$color_3: hsb((30, 100, 100));
		&:before,
		&:after {
			background-image: linear-gradient(var(--rotate), $color_1, $color_2 40%, $color_3);
		}
	}

	&.Yellow {
		$color_1: hsb((20, 100, 100));
		$color_2: hsb((55, 100, 100));
		$color_3: hsb((70, 100, 100));
		&:before,
		&:after {
			background-image: linear-gradient(var(--rotate), $color_1, $color_2 40%, $color_3);
		}
	}

	&.Green {
		$color_1: hsb((100, 100, 100));
		$color_2: hsb((120, 100, 100));
		$color_3: hsb((160, 100, 100));
		&:before,
		&:after {
			background-image: linear-gradient(var(--rotate), $color_1, $color_2 40%, $color_3);
		}
	}

	@keyframes SignInSpin {
		0% {
			--rotate: 0deg;
		}
		100% {
			--rotate: 360deg;
		}
	}
}

// @media (max-width: 50rem) {
// 	div.SignIn {
// 		background-color: hsb($color_background-100);
// 		padding: 0;
// 		div.SignInForm {
// 			width: 100%;
// 			margin: 0;
// 			border-radius: 0;
// 		}
// 	}

// 	.BoxGlow {
// 		&:before,
// 		&:after {
// 			display: none;
// 		}
// 	}
// }
