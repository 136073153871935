@import "../../styles/variables.scss", "../../styles/functions.scss";
@import "../../Design/Styles/Variables.scss";

div.VaporMarkdownPage {
	position: relative;
	max-width: $width_default;
	margin: lh(1) auto;
	padding: 0 lh(2);
	color: var(--color_white-300);
	display: flex;
	justify-content: space-between;
	gap: lh(2);

	div.MarkdownBody {
		background: $color-background;
		// background: hsb($color-background-hsb, 0.75);
		// backdrop-filter: blur(10px);
		// background-color: white;
		border: $border-width solid $color-text;
		padding: lh(2);
		color: $color-text;
		// mix-blend-mode: difference;

		.EditHeader {
		}
	}

	div.SideBar {
		// background-color: var(--color_background-200);
		// background-color: $color-background;
		flex: 0 0 lh(10);
		align-self: flex-start;
		position: -webkit-sticky; /* Safari */
		position: sticky;
		border-radius: $border_radius;
		top: lh(4);
		//padding:  0 lh(1);
		overflow: auto;

		.TableOfContents {
			margin: lh(1) lh(1);
		}

		.EditHeader {
			margin: lh(1) lh(1);
		}

		button {
			display: block;
			width: 100%;
			margin: lh(0.25) 0 0 0;
			padding: 0;
			min-height: lh(1.5);
		}

		svg {
			fill: white;
			height: lh(1);
			cursor: pointer;
		}
	}
}

@media (max-width: calc($width-mediaquery)) {
	div.MarkdownPage {
		flex-wrap: wrap;
		flex-direction: column-reverse;

		div.SideBar {
			flex: 0 0 100%;
		}
	}
}

// div.MarkdownEditor {
//     display: grid;
//     grid-template-areas:    "EditorHeader PreviewHeader"
//                             "Editor Preview"
//                             "EditorFooter PreviewFooter";
// 	grid-template-columns: 50% 50%;
// 	grid-template-rows: auto 1fr auto;
// 	height: 100%;
//     width: 100%;

// 	display: flex;
// 	flex-direction: row;

// 	width: 100%;
// 	height: 100%;

// 	.cm-theme {
// 		width: 100%;
// 	}

// 	div.LeftPane {
// 		display: flex;
// 		flex: 1 1 50%;
// 		flex-direction: column;
// 		align-items: flex-start; //center;
// 		min-width: 40rem;
// 		background-color: hsb([280, 5, 10]);

// 		div.CodeMirror {
// 			flex: 1 1 calc(100vh - lh(3));
// 			height: calc(100vh - lh(3));
// 			width: 30rem;
// 			overflow: auto;

// 			.cm-scroller {
// 				font-family: $font_monospace;
// 			}
// 		}

// 		div.Toolbar {
// 			flex: 1 1 lh(3);
// 		}
// 	}

// 	div.RightPane {
// 		flex: 1 1 50%;
// 		padding: lh(2);
// 		display: flex;
// 		height: 100vh;
// 		//align-items: center;
// 		//background-color: green;
// 		overflow-y: auto;
// 	}
// }
