@import "./variables.scss";
@import "./functions.scss";

/*
	https://type-scale.com/
	https://seek-oss.github.io/capsize/
	https://www.programmingfonts.org/
	https://philpl.gumroad.com/l/dank-mono
	https://www.monolisa.dev/
*/

html,
body {
	font-size: #{$font-size-base + "px"};
	font-family: $font_body;
	line-height: lh(1);
	font-weight: 400;
}

p {
	margin: lh(1) 0;
}

h1,
h2,
h3,
h4 {
	font-family: $font_headline;
	font-weight: 600;
	//letter-spacing: -0.025em;
}

// https://type-scale.com/
h1 {
	@include vr_font($font-size-900);
}

h2 {
	@include vr_font($font-size-800);
}

h3 {
	@include vr_font($font-size-700);
}

h4 {
	@include vr_font($font-size-600);
}

.small_text {
	@include vr_font($font-size-300);
}

.smaller_text {
	@include vr_font($font-size-200);
}

.smallest_text {
	@include vr_font($font-size-100);
}

/* make form elements easier to work with */
input,
button,
textarea,
select {
	font: inherit;
}

a {
	color: hsb($color_bright_pink);

	&:visited {
		color: hsb($color_bright_pink);
	}
}

code {
	font-family: $font_monospace;
}
