@use "sass:math";
@use "./Variables.scss";
@use "../../styles/variables.scss";
@use "../../styles/functions.scss";
@import "./Variables.scss";
@import "./Utility.scss";

.vapor_button {
	// margin: lh(1) 0;

	button {
		display: flex;
		gap: lh(0.25);
		justify-content: center;
		margin: 0;
		text-transform: uppercase;
		box-shadow: 0 0 0 2px $color-text inset;
		background: transparent;
		border: none;
		color: $color-text;
		font-size: var(--font-size-400);
		line-height: lh(1);
		border-radius: lh(0.25);
		cursor: pointer;
		font-weight: 600;

		svg {
			fill: white;
			height: lh(1);
		}

		&:hover,
		&:focus {
			outline: none;
			background: $color-text;
			color: $color-background;
			//box-shadow: 0 0 0 2px $color-text; //, 0 0 0 2px $color-background inset;
			// animation: dropShadow 1.6s infinite;
			svg {
				fill: $color-background;
			}
		}

		&:active {
			transform: translateY(calc($border-width * 1));
		}
		&:disabled,
		[disabled] {
			&:hover {
				background: $color-background;
				color: $color-text;
				box-shadow: 0 0 0 2px $color-text;
			}
			opacity: 0.5;
			cursor: not-allowed;
		}
	}

	&.icon {
	}

	&.transparent {
		button {
			box-shadow: none;
			&:hover,
			&:focus {
				box-shadow: 0 0 0 2px $color-text inset;
			}
		}
	}
}

.vapor_inline_button {
	display: inline;
	button {
		display: inline;
		margin: 0;
		padding: 0;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid $color-text;
		color: $color-text;
		cursor: pointer;
	}
}

.vapor_button_list {
	ul.vertical {
		list-style: none;
		margin-block-start: 0;
		margin-block-end: 0;
		padding-inline-start: 0;
		gap: lh(0.5);
		.vapor_button {
			width: 100%;
			button {
				width: 100%;
			}
		}
		li {
			margin-bottom: lh(1);
		}
		li:last-child {
			margin-bottom: lh(0);
		}
	}

	ul.horizontal {
		list-style: none;
		margin-block-start: 0;
		margin-block-end: 0;
		padding-inline-start: 0;
		display: flex;
		// justify-content: center;
		gap: lh(0.5);

		// &.flex_justify_right {
		// 	justify-content: flex-end;
		// }
	}
}

div.vapor_input {
	position: relative;
	display: block;
	margin: lh(1) 0;

	::selection {
		color: $color-text;
		color: red;
		background-color: $color-background;
	}

	input {
		-webkit-appearance: none;
		display: block;
		width: 100%;
		background: $color-background;
		border: none;
		box-shadow: 0 0 0 $border-width $color-text inset;
		border-radius: $border_radius;
		outline: none;
		font-family: $font_monospace;
		font-size: 1rem;
		line-height: lh(1);
		padding: lh(0.7) lh(0.5) lh(0.3) lh(0.5);
		// color: $color_text;
		color: $color_background_200;
		background: $color-text;
		box-shadow: 0 0 0 2px $color-text, 0 0 0 2px $color-background inset;
	}

	label {
		transition: 0.25s ease-in;
		position: absolute;
		top: lh(0.5);
		left: lh(0.5);
		display: block;
		color: $color-text;
		color: $color-background;
		font-family: $font_monospace;
		cursor: text;
	}

	input::placeholder {
		color: transparent;
	}

	&.Active {
		input {
			//border-color: hsb(hsb_modify($color_form_border, 0, 0, 0));
			//box-shadow: 0 0 0 .125em hsb(hsb_modify($color_form_border, 0, 0, 80)) inset;
			box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.75);
			box-shadow: 0 0 0 2px $color-text, 0 0 0 2px $color-background inset;
			background: $color-text;
			color: $color-background;
		}

		input::selection {
			color: $color-text;
			background-color: $color-background;
		}

		label {
			top: 0.25rem;
			transition: 0.125s ease-in-out;
			font-size: 0.7rem;
			line-height: 0.7rem;
			text-transform: uppercase;
			color: $color-background;
		}
	}

	&.Filled {
		label {
			top: 0.25rem;
			transition: 0.25s;
			font-size: 0.7rem;
			line-height: 0.7rem;
			color: $color-background;
			text-transform: uppercase;
		}

		&.Invalid {
			label {
				color: $color-background !important;
			}
		}
	}

	&::selection {
		color: $color-text;
		background-color: $color-background;
	}
}

// http://aleclownes.com/2017/02/01/crt-display.html

@keyframes scanline_flicker {
	$count: 0;
	@while $count <= 100 {
		$opacity: (math.random(4) + 6) / 10;
		#{$count + "%"} {
			opacity: $opacity;
		}
		$count: $count + 5;
	}
}

@keyframes textShadow {
	$count: 0;
	@while $count <= 100 {
		#{$count + "%"} {
			$x-offset: math.random(3);
			$y-offset: math.random(3);
			text-shadow: #{$x-offset + "px"} #{$y-offset + "px"} 1px rgba(0, 30, 255, 0.5),
				#{-1 * $x-offset + "px"} #{-1 * $y-offset + "px"} 1px rgba(255, 0, 80, 0.3);
		}
		$count: $count + 5;
	}
}

@keyframes dropShadow {
	$count: 0;
	$opacity: 0.75;
	$blur: 1px;
	@while $count <= 100 {
		#{$count + "%"} {
			$x-offset-blue: math.random(4) - 2;
			$y-offset-blue: math.random(4) - 2;
			$x-offset-red: math.random(4) - 2;
			$y-offset-red: math.random(4) - 2;
			$x-offset-green: math.random(4) - 2;
			$y-offset-green: math.random(4) - 2;
			filter: drop-shadow(#{$x-offset-blue + "px"} #{$y-offset-blue + "px"} $blur rgba(0, 0, 255, $opacity))
				drop-shadow(#{$x-offset-red + "px"} #{$y-offset-red + "px"} $blur rgba(255, 0, 0, $opacity))
				drop-shadow(#{$x-offset-green + "px"} #{$y-offset-green + "px"} $blur rgba(0, 255, 0, $opacity));
			// drop-shadow(#{1 * $x-offset + "px"} #{-1 * $y-offset + "px"} 0px rgba(0, 255, 0, $opacity))
			// drop-shadow(#{-1 * $x-offset + "px"} #{-1 * $y-offset + "px"} 0px rgba(255, 0, 00, $opacity));
		}
		$count: $count + 10;
	}
}

// .scanline_flicker::after {
// 	content: " ";
// 	display: block;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	bottom: 0;
// 	right: 0;
// 	background: rgba(16, 16, 16, 0.15);
// 	opacity: 0;
// 	z-index: 2;
// 	pointer-events: none;
// 	animation: scanline_flicker 0.15s infinite;
// }

.vapor_scanlines::before {
	content: " ";
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
		linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
	z-index: 2;
	background-size: 100% 2px, 3px 100%;
	pointer-events: none;
}

.vapor_scanlines_flicker::before {
	animation: scanline_flicker 0.15s infinite;
}

.crt {
	// animation: textShadow 1.6s infinite;
}

.crt_dropshadow {
	// animation: dropShadow 1.6s infinite;
}
