@import "../../styles/variables.scss", "../../styles/functions.scss";
//@import "math";

// https://codepen.io/mattgrosswork/pen/VwprebG

//$color-background: hsb((194, 65, 20));
// $color-action: hsb((260, 60, 100));
// $color-action: hsb((300, 60, 100));
// $color-text: hsb((180, 60, 100));
//$color-action: hsb((330, 100, 80));

// https://coolors.co/001326-8ef9f3-5efc8d-f06449-e40066
$color-blue-dark: (210, 100, 15);
$color-blue-light: (177, 43, 98);
$color-green: (138, 63, 99);
$color-purple-light: (206, 34, 87);
$color-purple: (248, 43, 82);
$color-orange: (10, 70, 94);
$color-pink: (333, 100, 89);

$color_purple: (271, 78, 80);
$color_yellow: (57, 67, 97);
$color_orange: (34, 100, 100);
$color_orange_dark: (14, 83, 83);
$color_red: (358, 92, 99);
$color_bright_blue: (169, 90, 100);
$color_bright_pink: (311, 48, 100);
$color_pink: (317, 34, 100);
$color_bright_green: (149, 79, 85);

//$color-background:hsb($color-blue-dark)$color-background-hsb:(210,100,5)$color-background-hsb: (280, 100, 5);
$color-background-hsb: (210, 100, 5);
$color-background: hsb($color-background-hsb);
$color-text: hsb($color-blue-light);
$color-text: hsb((177, 23, 98));
$color-borders: $color-text;
$border-width: 2px;

$color_background: hsl(240, 13%, 9%);
$color_text: hsl(180, 100%, 95%);
$color_red: hsl(0, 100%, 60%);

$color_background-100: hsb(hsb_modify($color-background-hsb, 0, 0, 0));
$color_background-200: hsb(hsb_modify($color-background-hsb, 0, 0, 0));
$color_background-300: hsb(hsb_modify($color-background-hsb, 0, 0, -6));
$color_background-400: hsb(hsb_modify($color-background-hsb, 0, 0, 0));
$color_background-500: hsb(hsb_modify($color-background-hsb, 0, 0, 8));
$color_background-600: hsb(hsb_modify($color-background-hsb, 0, 0, 16));
$color_background-700: hsb(hsb_modify($color-background-hsb, 0, -20, 40));
$color_background-800: hsb(hsb_modify($color-background-hsb, 0, -20, 60));

// :export {
// 	font_monospace: $font_monospace;
// 	color_background: hsb($color-background-hsb);
// 	color_background_100: $color_background-100;
// 	color_background_200: $color_background-200;
// 	color_background_300: $color_background-300;
// 	color_background_400: $color_background-400;
// 	color_background_500: $color_background-500;
// 	color_background_600: $color_background-600;
// 	color_background_700: $color_background-700;
// 	color_background_700: $color_background-800;
// 	color_text: $color-text;
// 	color_bright_green: hsb($color_bright_green);
// 	color_bright_purple: hsb($color_purple);
// 	color_bright_orange: hsb($color_orange);
// 	color_bright_pink: hsb($color_bright_blue);
// }

// https://coolors.co/110b11-ff299c-66ffff-279af1-2176ae
// https://coolors.co/110b11-2e86ab-f6f5ae-f5f749-f24236
// https://coolors.co/001326-b9ffb7-abedc6-98d9c2-f19a3e
// https://coolors.co/001326-5efc8d-8ef9f3-93bedf-8377d1

$color_background: hsl(240, 13%, 9%);
$color_background_100: hsl(0, 0%, 0%);
$color_background_200: hsl(0, 0%, 0%);
$color_background_300: hsl(0, 0%, 0%);
$color_background_400: hsl(240, 13%, 9%);
$color_background_500: hsl(240, 10%, 14%);
$color_background_600: hsl(240, 7%, 21%);
$color_background_700: hsl(240, 4%, 27%);
$color_red: hsl(0, 100%, 60%);
$color_red_100: hsl(0, 100%, 35%);
$color_red_200: hsl(0, 100%, 43%);
$color_red_300: hsl(0, 100%, 50%);
$color_red_400: hsl(0, 100%, 60%);
$color_red_500: hsl(0, 90%, 70%);
$color_red_600: hsl(0, 84%, 77%);
$color_red_700: hsl(0, 79%, 85%);
$color_orange: hsl(30, 100%, 60%);
$color_orange_100: hsl(30, 100%, 35%);
$color_orange_200: hsl(30, 100%, 43%);
$color_orange_300: hsl(30, 100%, 50%);
$color_orange_400: hsl(30, 100%, 60%);
$color_orange_500: hsl(30, 90%, 70%);
$color_orange_600: hsl(30, 84%, 77%);
$color_orange_700: hsl(30, 79%, 85%);
$color_yellow: hsl(60, 99%, 60%);
$color_yellow_100: hsl(60, 100%, 35%);
$color_yellow_200: hsl(60, 100%, 42%);
$color_yellow_300: hsl(60, 100%, 50%);
$color_yellow_400: hsl(60, 99%, 60%);
$color_yellow_500: hsl(60, 90%, 70%);
$color_yellow_600: hsl(60, 84%, 77%);
$color_yellow_700: hsl(60, 79%, 85%);
$color_green: hsl(140, 97%, 55%);
$color_green_100: hsl(140, 100%, 29%);
$color_green_200: hsl(140, 100%, 37%);
$color_green_300: hsl(140, 100%, 45%);
$color_green_400: hsl(140, 97%, 55%);
$color_green_500: hsl(140, 88%, 65%);
$color_green_600: hsl(140, 82%, 72%);
$color_green_700: hsl(140, 77%, 79%);
$color_blue: hsl(180, 100%, 60%);
$color_blue_100: hsl(180, 100%, 35%);
$color_blue_200: hsl(180, 100%, 43%);
$color_blue_300: hsl(180, 100%, 50%);
$color_blue_400: hsl(180, 100%, 60%);
$color_blue_500: hsl(180, 90%, 70%);
$color_blue_600: hsl(180, 84%, 77%);
$color_blue_700: hsl(180, 79%, 85%);
$color_purple: hsl(290, 100%, 60%);
$color_purple_100: hsl(290, 100%, 35%);
$color_purple_200: hsl(290, 100%, 43%);
$color_purple_300: hsl(290, 100%, 50%);
$color_purple_400: hsl(290, 100%, 60%);
$color_purple_500: hsl(290, 90%, 70%);
$color_purple_600: hsl(290, 84%, 77%);
$color_purple_700: hsl(290, 79%, 85%);
$color_pink: hsl(320, 100%, 55%);
$color_pink_100: hsl(320, 100%, 30%);
$color_pink_200: hsl(320, 100%, 37%);
$color_pink_300: hsl(320, 100%, 45%);
$color_pink_400: hsl(320, 100%, 55%);
$color_pink_500: hsl(320, 90%, 65%);
$color_pink_600: hsl(320, 84%, 72%);
$color_pink_700: hsl(320, 80%, 80%);
$color_text: hsl(180, 100%, 95%);
$color_text_100: hsl(180, 31%, 64%);
$color_text_200: hsl(180, 37%, 72%);
$color_text_300: hsl(180, 48%, 81%);
$color_text_400: hsl(180, 100%, 95%);
$color_text_500: hsl(180, 100%, 98%);
$color_text_600: hsl(0, 0%, 100%);
$color_text_700: hsl(0, 0%, 100%);

:export {
	font_monospace: $font_monospace;
	color_background: hsl(240, 13%, 9%);
	color_background_100: hsl(0, 0%, 0%);
	color_background_200: hsl(0, 0%, 0%);
	color_background_300: hsl(0, 0%, 0%);
	color_background_400: hsl(240, 13%, 9%);
	color_background_500: hsl(240, 10%, 14%);
	color_background_600: hsl(240, 7%, 21%);
	color_background_700: hsl(240, 4%, 27%);
	color_red: hsl(0, 100%, 60%);
	color_red_100: hsl(0, 100%, 35%);
	color_red_200: hsl(0, 100%, 43%);
	color_red_300: hsl(0, 100%, 50%);
	color_red_400: hsl(0, 100%, 60%);
	color_red_500: hsl(0, 90%, 70%);
	color_red_600: hsl(0, 84%, 77%);
	color_red_700: hsl(0, 79%, 85%);
	color_orange: hsl(30, 100%, 60%);
	color_orange_100: hsl(30, 100%, 35%);
	color_orange_200: hsl(30, 100%, 43%);
	color_orange_300: hsl(30, 100%, 50%);
	color_orange_400: hsl(30, 100%, 60%);
	color_orange_500: hsl(30, 90%, 70%);
	color_orange_600: hsl(30, 84%, 77%);
	color_orange_700: hsl(30, 79%, 85%);
	color_yellow: hsl(60, 99%, 60%);
	color_yellow_100: hsl(60, 100%, 35%);
	color_yellow_200: hsl(60, 100%, 42%);
	color_yellow_300: hsl(60, 100%, 50%);
	color_yellow_400: hsl(60, 99%, 60%);
	color_yellow_500: hsl(60, 90%, 70%);
	color_yellow_600: hsl(60, 84%, 77%);
	color_yellow_700: hsl(60, 79%, 85%);
	color_green: hsl(140, 97%, 55%);
	color_green_100: hsl(140, 100%, 29%);
	color_green_200: hsl(140, 100%, 37%);
	color_green_300: hsl(140, 100%, 45%);
	color_green_400: hsl(140, 97%, 55%);
	color_green_500: hsl(140, 88%, 65%);
	color_green_600: hsl(140, 82%, 72%);
	color_green_700: hsl(140, 77%, 79%);
	color_blue: hsl(180, 100%, 60%);
	color_blue_100: hsl(180, 100%, 35%);
	color_blue_200: hsl(180, 100%, 43%);
	color_blue_300: hsl(180, 100%, 50%);
	color_blue_400: hsl(180, 100%, 60%);
	color_blue_500: hsl(180, 90%, 70%);
	color_blue_600: hsl(180, 84%, 77%);
	color_blue_700: hsl(180, 79%, 85%);
	color_purple: hsl(290, 100%, 60%);
	color_purple_100: hsl(290, 100%, 35%);
	color_purple_200: hsl(290, 100%, 43%);
	color_purple_300: hsl(290, 100%, 50%);
	color_purple_400: hsl(290, 100%, 60%);
	color_purple_500: hsl(290, 90%, 70%);
	color_purple_600: hsl(290, 84%, 77%);
	color_purple_700: hsl(290, 79%, 85%);
	color_pink: hsl(320, 100%, 75%);
	color_pink_100: hsl(320, 100%, 50%);
	color_pink_200: hsl(320, 100%, 57%);
	color_pink_300: hsl(320, 100%, 65%);
	color_pink_400: hsl(320, 100%, 75%);
	color_pink_500: hsl(320, 90%, 85%);
	color_pink_600: hsl(321, 84%, 93%);
	color_pink_700: hsl(0, 0%, 100%);
	color_text: hsl(180, 100%, 95%);
	color_text_100: hsl(180, 31%, 64%);
	color_text_200: hsl(180, 37%, 72%);
	color_text_300: hsl(180, 48%, 81%);
	color_text_400: hsl(180, 100%, 95%);
	color_text_500: hsl(180, 100%, 98%);
	color_text_600: hsl(0, 0%, 100%);
	color_text_700: hsl(0, 0%, 100%);
}
