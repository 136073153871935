.vapor_flex_justify_right {
	justify-content: flex-end;
}

.vapor_flex_justify_center {
	justify-content: center;
}

.vapor_margin-top-000 {
	margin-top: 0;
}

.vapor_margin-top-100 {
	margin-top: lh(1);
}

.vapor_margin-bottom-000 {
	margin-top: 0;
}

.vapor_margin-bottom-100 {
	margin-top: lh(1);
}

.vapor_padding-000 {
	padding: 0;
}

.vapor_padding-100 {
	padding: lh(0.25);
}

.vapor_padding-200 {
	padding: lh(0.5);
}

.vapor_padding-300 {
	padding: lh(0.75);
}

.vapor_padding-400 {
	padding: lh(1);
}
