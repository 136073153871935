@import "../styles/variables.scss", "../styles/functions.scss", "../styles/mixins.scss", "../styles/typography.scss";

// $color_form_border: (230, 70, 90);
// $color_form_background: (250, 20, 17);
// $border_radius: lh(0.25);

div.InputFancy {
	position: relative;
	display: block;
	margin: lh(1) 0;

	input {
		-webkit-appearance: none;
		display: block;
		width: 100%;
		background: rgba(0, 0, 0, 0.125); //hsb($color_form_background);
		border: none;
		box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25) inset;
		border-radius: $border_radius;
		outline: none;
		font-family: $font_monospace;
		font-size: 1rem;
		line-height: lh(1);
		padding: lh(0.7) lh(0.5) lh(0.3) lh(0.5);
		color: white;
	}

	input::placeholder {
		color: transparent;
	}

	&.Active {
		input {
			//border-color: hsb(hsb_modify($color_form_border, 0, 0, 0));
			//box-shadow: 0 0 0 .125em hsb(hsb_modify($color_form_border, 0, 0, 80)) inset;
			box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.75) inset;
		}

		label {
			top: 0.25rem;
			transition: 0.125s ease-in-out;
			font-size: 0.7rem;
			line-height: 0.7rem;
			text-transform: uppercase;
		}
	}

	&.Invalid {
		label {
			color: hsb($color_bright_red) !important;
		}
	}

	&.Filled {
		label {
			top: 0.25rem;
			transition: 0.25s;
			font-size: 0.7rem;
			line-height: 0.7rem;
			color: rgba(255, 255, 255, 0.5);
			text-transform: uppercase;
		}
	}

	label {
		transition: 0.25s ease-in;
		position: absolute;
		top: lh(0.5);
		left: lh(0.5);
		display: block;
		color: white;
		font-family: $font_monospace;
		cursor: text;
	}
}

div.CheckboxFancy {
	display: flex;

	div.label {
		input[type="checkbox"] {
			display: none;
		}
	}

	span.checkbox {
		flex: 0 0 1.5rem;

		label {
			display: flex;
			cursor: pointer;
			transition: all 0.3s ease;

			// Hide the actual input
			input {
				position: absolute;
				left: -9999px;
			}

			// Checkbox dot
			input:checked + div span:first-of-type {
				animation: CheckboxFancy_DotJump 0.3s ease;
			}

			input:checked + div span:first-of-type::before {
				background-color: white;
			}

			> div {
				display: flex;
				align-items: center;
				height: 1.5rem;
				width: 1em;

				> span:first-of-type {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 1rem;
					height: 1rem;
					background-color: var(--color_background-200);
					box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25) inset;
				}

				span:first-of-type::before {
					content: "";
					width: calc(1rem - 8px);
					height: calc(1rem - 8px);
					transition: all 0.3s ease;
				}
			}

			input[type="checkbox"] + div span,
			input[type="checkbox"] + div span::before {
				border-radius: 0.1rem;
			}
		}
	}
}

@keyframes CheckboxFancy_DotJump {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.25);
	}
	100% {
		transform: scale(1);
	}
}

@mixin ButtonFancy($color, $transparent: 0, $disabledTransparent: 0) {
	position: relative;
	outline: none;
	outline-offset: 0;
	display: inline-block;
	box-sizing: border-box;
	margin: 0;
	padding: 0.75rem;
	border: none;
	border-radius: $border_radius;
	font-family: $font_headline;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 800;
	text-transform: uppercase;
	transition: ease-in 0.1s;
	cursor: pointer;
	min-height: 3rem;
	color: rgba(255, 255, 255, 0.9);

	@if $transparent == 0 {
		background: hsb($color);
		box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.15) inset;
	} @else {
		background: transparent;
		box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.15) inset;
	}

	svg {
		padding: 0;
		float: left;
		margin-right: 0.5rem;
		margin-top: -0.25rem;
		height: 1.5rem;
		width: 1.5rem;
		fill: hsb(hsb_modify($color, 0, 0, 60));
	}

	&:hover,
	&:focus {
		color: rgba(255, 255, 255, 1);
		@if $transparent == 0 {
			box-shadow: 0 0 0 0.125em hsb((hue($color), 0, 100)) inset;
		} @else {
			box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 1) inset;
		}
	}

	&:active {
		transform: translateY(0.125em);
	}

	&:disabled,
	[disabled] {
		background: transparent;
		box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.1) inset;
		color: hsb(hsb_modify((0, 0, 20), 0, 0, 20));
		cursor: not-allowed;
	}
}

button.ButtonFancy {
	@include ButtonFancy(hsb_modify($color_bright_blue, 0, 0, 0));

	&.short {
		margin: 2rem 0 0.5rem 0;
		padding: 0.5rem 0.75rem 0.5rem 0.75rem !important;
		line-height: 1rem !important;
	}

	&.short {
		margin: 2rem 0 0.5rem 0;
		padding: 0.75rem 0.75rem 0.75rem 0.75rem !important;
		vertical-align: middle;
		line-height: 1rem !important;
	}

	&.transparent {
		@include ButtonFancy(hsb_modify($color_background_100, 0, 0, 0), $transparent: 1);
	}

	&.pink {
		@include ButtonFancy(hsb_modify($color_bright_pink, 0, 0, 0));
	}

	&.red {
		@include ButtonFancy(hsb_modify($color_bright_red, 0, 5, 5));
	}

	&.green {
		@include ButtonFancy(hsb_modify($color_bright_green, 0, 5, 5));
	}

	&.purple {
		@include ButtonFancy($color_bright_purple);
	}

	&.blue {
		@include ButtonFancy($color_bright_blue);
	}

	&.submit {
		@include ButtonFancy(hsb_modify($color_bright_pink, 0, 0, 0), $transparent: 0, $disabledTransparent: 1);
	}

	&.disabled {
		background: transparent;
		box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.1) inset;
		color: hsb(hsb_modify((0, 0, 20), 0, 0, 20));
		cursor: not-allowed;
	}
}

$circle-size: 1.5em;
$circle-stroke-color: #fff;
$circle-radius: 45;

@function get-dash-value($radius, $percentage) {
	// Using $radius, calculate circumference.
	$circumference: 2 * 3.1415927 * $radius;
	// Convert percentage to decimal.
	// i.e. 50% = 0.5.
	$percentage-as-decimal: calc($percentage / 100%);
	// Return unit value.
	@return $circumference * $percentage-as-decimal;
}

.circle {
	display: absolute;
	height: $circle-size;
	width: $circle-size;
	position: absolute;
	//margin: 0 auto;
	left: 50%;
	top: calc(50% + (#{$circle-size} / 2) + 0.25rem);
	transform: translateY(-100%) translateX(-50%);
}

.circle__svg {
	animation: 2s linear infinite both circle__svg;
}

@keyframes circle__svg {
	0% {
		transform: rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg);
	}
}

.circle__svg-circle {
	animation: 1.4s ease-in-out infinite both circle__svg-circle;
	fill: transparent;
	stroke: $circle-stroke-color;
	stroke-dasharray: get-dash-value($circle-radius, 100%);
	stroke-linecap: round;
	stroke-width: 10;
	transform-origin: 50% 50%;
}

@keyframes circle__svg-circle {
	0%,
	25% {
		stroke-dashoffset: get-dash-value($circle-radius, 97%);
		transform: rotate(0);
	}

	50%,
	75% {
		stroke-dashoffset: get-dash-value($circle-radius, 25%);
		transform: rotate(45deg);
	}

	100% {
		stroke-dashoffset: get-dash-value($circle-radius, 97%);
		transform: rotate(360deg);
	}
}
