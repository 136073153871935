@import "../styles/variables.scss", "../styles/functions.scss";

div.Navigation {
	display: block;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 25%);

	nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: $width_default;
		margin: 0 auto;
		padding: 0;

		> div.Hamburger {
			display: none;
		}

		> div.Logo {
			padding: 0.75rem lh(1);
			a {
				text-decoration: none;
				color: hsb($color_font_white);
				font-weight: 800;
				font-family: $font_monospace;
				letter-spacing: lh(0.1);
			}
		}

		> ul.Menu {
			display: flex;
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				display: block;
				padding: 0.5rem;
				font-family: $font_monospace;
				color: hsb($color_font_white);
				letter-spacing: lh(0.1);
				padding: 0.75rem lh(1);
				> * {
					text-transform: uppercase;
				}
				a {
					text-decoration: none;
					color: hsb($color_font_white);
					span {
						font-weight: 700;
						margin-right: 0.5em;
					}
				}
				> button {
					font-family: inherit;
					color: inherit;
					background-color: transparent;
					border: none;
					margin: 0;
					padding: 0;
					cursor: pointer;
				}
			}
		}
	}
}

@media (max-width: $width_mediaquery) {
	div.Navigation {
		nav {
			display: block;

			> div.Hamburger {
				display: block;
				float: right;
				padding: lh(0.5) lh(1) 0 0;
				cursor: pointer;

				> svg {
					color: white;
					fill: white;
					width: lh(1);
					height: lh(1);
					opacity: 0.75;

					&:hover {
						opacity: 1;
					}
				}
			}
			> ul.Menu {
				display: none;
			}
		}
	}

	div.Navigation.HamburgerOpen {
		nav {
			> ul.Menu {
				display: block;
				li {
					display: block;
				}
			}
		}
	}
}
