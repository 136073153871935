@import "../styles/variables.scss", "../styles/functions.scss";

.MarkdownContent {
	// color: var(--color_white-400);

	h1,
	h2,
	h3,
	h4 {
		//color: var(--color_white-700);
	}

	pre {
		code {
			display: block;
			padding: lh(1);
			border-radius: lh(0.5);
			background-color: rgba(255, 255, 255, 0.05);
			white-space: pre-wrap;
		}
	}

	a,
	a:visited {
		&:hover {
			transition: all 0.25s ease-in;
			//background: hsb(hsb_modify($color_bright_pink, 10, 0, 0));
			//background: white;
			box-shadow: 0 0 0 2px rgba(0, 0, 0, 1), 0 0 0 3px rgba(255, 255, 255, 1), 0 0 0 5px rgba(0, 0, 0, 0.5);
		}
		transition: all 0.25s ease-in;
		background: hsb($color_bright_pink);
		color: var(--color_background-000);
		text-decoration: none;
		font-weight: 500;
		padding: 0 lh(0.25);
		//box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset;
	}

	ul {
		li {
			ul {
				color: var(--color_white-100);
				//opacity: 0.75;
			}
		}
	}

	ul.contains-task-list {
		> li.task-list-item {
			list-style: none;
			padding-inline: 0;
			margin-left: lh(-1);
			div.CheckboxFancy {
				div.label {
					input[type="checkbox"] {
						display: none;
					}
				}
			}
		}
	}
}
