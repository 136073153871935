@import "../styles/variables.scss", "../styles/functions.scss";

:root {
	--font-size-100: #{$font-size-100 + "rem"};
	--font-size-200: #{$font-size-200 + "rem"};
	--font-size-300: #{$font-size-300 + "rem"};
	--font-size-400: #{$font-size-400 + "rem"};
	--font-size-500: #{$font-size-500 + "rem"};
	--font-size-600: #{$font-size-600 + "rem"};
	--font-size-700: #{$font-size-700 + "rem"};
	--font-size-800: #{$font-size-800 + "rem"};
	--font-size-900: #{$font-size-900 + "rem"};

	--font-family-monospace: #{$font_monospace};
	--font-family-body: #{$font_body};

	--color_background-000: #{hsb($color_background-000)};
	--color_background-100: #{hsb($color_background-100)};
	--color_background-200: #{hsb($color_background-200)};
	--color_background-300: #{hsb($color_background-300)};
	--color_background-400: #{hsb($color_background-400)};

	--color_white-000: #{hsb($color_white-000)};
	--color_white-100: #{hsb($color_white-100)};
	--color_white-200: #{hsb($color_white-200)};
	--color_white-300: #{hsb($color_white-300)};
	--color_white-400: #{hsb($color_white-400)};
	--color_white-500: #{hsb($color_white-500)};
	--color_white-600: #{hsb($color_white-600)};
	--color_white-700: #{hsb($color_white-700)};
	--color_white-800: #{hsb($color_white-800)};

	--font-size-100: #{$font-size-100}rem;
	--font-size-200: #{$font-size-200}rem;
	--font-size-300: #{$font-size-300}rem;

	--width-mediaquery: 40rem;
}
