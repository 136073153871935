@import "../styles/variables.scss", "../styles/functions.scss";
@import "../Design/Styles/Variables.scss";

div.FancyModal {
	div.Shade {
		backdrop-filter: blur(0.25rem);
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		position: fixed;
		//position: absolute;
		right: 0;
		top: 0;
		overflow: auto;
		transition: all ease-in 0.5s;
		z-index: 1000;
		display: flex;
		align-items: center;
		justify-content: center;

		div.Modal {
			// background: $color-background;
			// box-shadow: 0 0 0 $border-width $color-text, 0 0 1rem 1rem rgba(0, 0, 0, 0.25);
			// padding: lh(1);
			// // border-radius: $border_radius;
			// width: 25rem;

			// button {
			// 	width: 100%;
			// }
		}
	}
}
