@import "../../styles/variables.scss", "../../styles/functions.scss";
@import "../../Design/Styles/Variables.scss";

div.VaporMarkdownEditor {
	$border-color: var(--color_background-300);
	$border-width: 2px;

	display: grid;
	z-index: 0;
	grid-template-areas:
		"Header"
		"Body"
		"Footer";
	//grid-template-columns: 50% 50%;
	grid-template-rows: auto 1fr auto;
	height: 100vh;
	width: 100%;
	padding: lh(1);
	//background: red;
	//	background-color: hsb($color_background-000);

	.cm-theme {
		width: 100%;
	}

	div.Header {
		grid-area: Header;
		//margin-top: lh(0.5);
		display: flex;
		border: $border-width solid $color-text;
		z-index: 0;
		// backdrop-filter: blur(10px);

		div.Title {
			flex: 0 1 100%;
			padding: lh(0.5);
			text-decoration: none;
			color: hsb($color_font_white);
			font-weight: 800;
			font-family: $font_monospace;
			letter-spacing: lh(0.1);
		}

		div.Tabs {
			flex: 0 0 auto;
			align-self: flex-end;
			ul {
				padding: 0;
				margin: 0 lh(1);
				display: block;

				li {
					padding: lh(0.25) lh(0.5);
					margin: 0 lh(0.25);
					list-style: none;
					display: inline-block;
					width: lh(4);
					text-align: center;

					button {
						font-family: $font_monospace;
						text-transform: uppercase;
						color: rgba(255, 255, 255, 0.5);
					}
				}

				li.active {
					position: relative;
					border-radius: $border_radius $border_radius 0 0;
					color: white;
					box-shadow: 0 0 0 $border-width $color-text;
					z-index: 100;

					button {
						color: rgba(255, 255, 255, 1);
					}

					&::after {
						content: "";
						display: block;
						position: absolute;
						bottom: calc($border-width * 2 * -1);
						left: 0;
						height: calc($border-width * 2);
						width: 100%;
						background-color: $color_background-400;
						z-index: 0;
					}
				}
			}
		}
	}

	div.Body {
		grid-area: Body;
		// background-color: $color-background;
		overflow: scroll;
		z-index: 0;
		// margin: lh(1);
		border-left: $border-width solid $color-text;
		border-right: $border-width solid $color-text;
		backdrop-filter: blur(10px);

		div.Preview {
			padding: lh(1);
		}
		& > div {
			display: none;
		}
		& > div.active {
			display: block;
		}

		div.Files {
			div.filepond--wrapper {
				margin: 0 auto;
				max-width: lh(30);
			}
		}
	}

	div.Footer {
		grid-area: Footer;
		position: relative;
		padding: lh(0.5);
		text-align: right;
		border: $border-width solid $color-text;
		border-top: none;
		backdrop-filter: blur(10px);

		button {
			margin: 0 0 0 0.75rem !important;
			width: 7rem;
			// min-height: lh(1.5);
			// padding: 0 0.75rem !important;
		}

		div.AutoSave {
			position: absolute;
			top: lh(-1.5);
			right: lh(1.5);
			background: rgba(0, 0, 0, 0.5);
			pointer-events: none;

			svg {
				position: absolute;
				display: block;
				fill: white;
				transition: all 0.25s ease-in-out 1s;
				height: lh(1);
				width: lh(1);
				opacity: 0;
			}

			&.Saved {
				svg.Check {
					opacity: 0.5;
				}
			}

			&.Unsaved {
				svg.Cloud {
					opacity: 0.5;
				}
			}

			&.Saving {
				svg.Refresh {
					opacity: 0.5;
				}
			}
		}
	}
}

div.CodeMirror1 {
	// flex: 1 1 calc(100vh - lh(3));
	// height: calc(100vh - lh(3));
	//width: 30rem;
	// overflow: auto;
	background: transparent;
	padding: lh(1);

	.cm-editor {
		background: transparent;
		.cm-focused {
			outline: none !important;
		}
	}

	.cm-gutters {
		display: none;
		background: $color_background-300;
		color: $color_background-700;
		font-size: var(--font-size-300);
		line-height: lh(1);
		.cm-lineNumbers {
			.cm-gutterElement {
				padding-left: lh(0.5);
			}
		}
	}

	.cm-activeLine,
	.cm-activeLineGutter {
		background: rgba(255, 255, 255, 0.1);
		background-color: $color_background-500;
		color: white;
	}

	.cm-activeLineGutter {
	}

	.cm-scroller {
		font-family: $font_monospace;
		font-size: #{$font-size-base + "px"};
		line-height: lh(1);
		background: transparent;
	}

	// MD: Headlines
	.ͼ11 {
		color: #ff7edb;
		// MD: ##
		&.ͼ13 {
			color: #ff7edb;
		}
	}

	// MD: Bullet list
	.ͼ10 {
		color: #fede5d;
		// MD: ##
		&.ͼ13 {
			color: white;
		}
		&.ͼv {
			color: #72f1b8;
		}
	}

	// MD: Italic
	.ͼy {
		color: #2ee2fa;
		font-style: italic;
		&.ͼ13 {
			color: #2ee2fa;
		}
	}

	// MD: Bold
	.ͼx {
		color: #2ee2fa;
		font-style: none;
		font-weight: 800;
		&.ͼ13 {
			color: #2ee2fa;
		}
	}

	// MD: Blockquote?
	.ͼ13 {
		color: #72f1b8;
	}

	.cm-tooltip-autocomplete {
		background: var(--color_background-300);
		border-radius: $border_radius;
		ul {
			max-height: lh(5);
			li {
				padding: 0 lh(0.5);
				font-size: var(--font-size-300);
				font-family: $font_monospace;
				letter-spacing: 0;
				line-height: lh(1);
				border-radius: $border_radius;

				.cm-completionIcon {
					display: none;
				}

				.cm-completionLabel {
					font-weight: 400;
				}

				.cm-completionDetail {
					font-style: normal !important;
				}

				.cm-completionMatchedText {
					text-decoration: none;
					font-weight: 800;
					border-bottom: 1px solid rgba(255, 255, 255, 0.5);
					//background: var(--color_background-400);
				}

				&[aria-selected] {
					background: var(--color_background-400);
				}
			}
		}
	}
}

// @media (max-width: 70rem) {
// 	div.MarkdownEditor {
// 		display: grid;
// 		grid-template-areas: "LeftHeader" "LeftPane" "LeftFooter" "RightHeader" "RightPane" "RightFooter";
// 		grid-template-columns: none;
// 		grid-template-rows: none;
// 		height: auto;
// 		width: 100%;
// 		div.LeftPane,
// 		div.RightPane {
// 			overflow: auto;
// 		}

// 		div.RightPane {
// 			padding: lh(1);
// 		}
// 	}
// }

.canvas {
	color: #848bbd;
	color: #ff8b39;
	color: #b6b1b1;
	color: #f73232;
	color: #fe4450;
	color: #28b881;
	color: #fede5d;
	color: #f97e72;
	color: #f97e72;
	color: #f97e72;
	color: #1afc65;
	color: #36f9f6;
	color: #fe4450;
	color: #72f1b8;
	color: #36f9f6;
	color: #fede5d;
	color: #fede5d;
	color: #fe4450;
	color: #d50;
	color: #36f9f6;
	color: #72f1b8;
	color: #2ee2fa;
	color: #fede5d;
	color: #fede5d;
	color: #fede5d;
	color: #fede5d;
	color: #f97e72;
	color: #fe4450;
	color: #36f9f6;
	color: #f735c0;
	color: #fc98df;
	color: #ff7edb;
	color: #b6b1b1;
	color: #fede5d;
	color: #72f1b8;
	color: #72f1b8;
	color: #72f1b8;
	color: #2ee2fa;
	color: #f97e72;
	color: #f97e72;
}
