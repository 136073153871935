@import "../styles/variables.scss", "../styles/functions.scss";

// .Spinner {
// 	width: lh(5);
// 	height: lh(5);

// 	position: relative;
// 	margin: lh(5) auto;
// }

// .double-bounce1,
// .double-bounce2 {
// 	width: 100%;
// 	height: 100%;
// 	border-radius: 50%;
// 	background-color: hsb($color_bright_purple);
// 	opacity: 0.75;
// 	position: absolute;
// 	top: 0;
// 	left: 0;

// 	-webkit-animation: sk-bounce 2s infinite ease-in-out;
// 	animation: sk-bounce 2s infinite ease-in-out;
// }

// .double-bounce2 {
// 	-webkit-animation-delay: -1s;
// 	animation-delay: -1s;
// 	background-color: hsb($color_bright_teal);
// }

// @-webkit-keyframes sk-bounce {
// 	0%,
// 	100% {
// 		-webkit-transform: scale(0);
// 	}
// 	50% {
// 		-webkit-transform: scale(1);
// 	}
// }

// @keyframes sk-bounce {
// 	0%,
// 	100% {
// 		transform: scale(0);
// 		-webkit-transform: scale(0);
// 	}
// 	50% {
// 		transform: scale(1);
// 		-webkit-transform: scale(1);
// 	}
// }

.Spinner {
	width: lh(4);
	height: lh(4);
	background-color: hsb($color_bright_pink);
	margin: lh(3) auto;
	animation: sk-rotateplane 1.5s infinite ease-in-out;
}

@keyframes sk-rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	}
	50% {
		background-color: hsb($color_bright_pink);
		transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
	}
	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
	}
}
