@import "../styles/variables.scss", "../styles/functions.scss";

div.TableOfContents {
	padding: none;
	ul {
		list-style: none;
		margin: 0;
		padding-inline-start: 0;
		li {
			margin: 0;
			padding: 0;
			ul {
				padding-inline-start: lh(1);
			}
		}
	}

	li > a {
		text-decoration: none;
		color: white;
	}

	li.active > a {
		font-weight: 800;
	}
}
