.cm-editor.cm-focused {
	outline: none;
}

.cmt-url {
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 15rem;
}

.cm-activeLine {
	.cmt-url {
		display: inline;
		white-space: normal;
		max-width: none;
	}
}
