@import "../../styles/variables.scss", "../../styles/functions.scss";

.worldmap {
	// background: blue;
	svg {
		width: 100%;
		height: auto;
	}
}

div.IpInformation {
	background-color: hsb($color_background_100);
	position: relative;
	// opacity: .85;
	// backdrop-filter: blur(5px);
	border-radius: lh(0.25);
	//border: 1px solid hsb(hsb_modify($color_background_lighter, 0, 0, 10));
	// box-shadow:
	//     lh(.25) lh(.25) lh(2) lh(0) rgba(0, 0, 0, .5),
	//     0 0 0 1px hsb(hsb_modify($color_background_lighter, 0, -20, 20)),
	//     0 0 0 2px black,
	//     inset 0 0 0 1px black;
	width: 50rem;
	margin: 3rem auto;
	padding: lh(1) lh(2);
	color: white;
	font-family: "VisbyRoundCF"; //'CartographCF';

	$border: 1px; //lh(.125);
	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		margin: -$border; /* !importanté */
		border-radius: inherit; /* !importanté */
		background: linear-gradient(to bottom right, hsb((300, 100, 100)), hsb((180, 100, 100)));
	}

	a,
	a:visited {
		color: hsb($color_bright_pink);
	}

	dl {
		margin: 0;
		display: grid;
		grid-template-columns: 7.5rem auto;

		dt {
			grid-column: 1;
			justify-self: end;
			display: block;
			font-weight: 400;
			opacity: 0.5;
		}
		dt:after {
			content: ":";
		}

		dd {
			grid-column: 2;
			display: block;
			margin: 0 0 0 lh(0.5);
			font-weight: 400;
		}

		span.ip {
			font-weight: 600;
		}
	}
}
